import React from 'react';
import PropTypes from 'prop-types';
// Styled Components
import { LandingSocialShare, LandingSocialShareLine, LandingSocialShareLink, Paragraph } from './StyledComponents';
import { Instagram, Facebook } from 'react-feather';
// Getting Arrays
import { D } from '../constants/dictionary';

const SocialShare = ({instagram, facebook}) => {
  return (
    <LandingSocialShare>
      <Paragraph color="var(--c400)">{D.landing.socialFollow}</Paragraph>
      <LandingSocialShareLine />
      {instagram && <LandingSocialShareLink aria-label="Enlace para instagram" href={instagram} target="_blank" rel="noopener norefferer"><Instagram size={24} /></LandingSocialShareLink>}
      {facebook && <LandingSocialShareLink aria-label="Enlace para facebook" href={facebook} target="_blank" rel="noopener norefferer"><Facebook size={24} /></LandingSocialShareLink>}
    </LandingSocialShare>
  );
};

SocialShare.propTypes = {
  instagram: PropTypes.string,
  facebook: PropTypes.string,
};

export default SocialShare;
