import React from "react";
/** Calling GraphQL */
import { useStaticQuery, graphql } from "gatsby";
/** Importing functional components */
import { DefaultPage } from "../layout/DefaultPage";
import SocialShare from "../components/SocialShare";
/** Importing Styled Components */
import {LandingWrapper ,LandingContainer, ButtonL, HeadingNotVisible} from './../components/StyledComponents';
import StaticImgComponent from "../components/StaticImg";
/** Importing Dictionaries */
import { D } from './../constants/dictionary';

const IndexPage = () => {

  /** Getting landing data with staticQuery */
  const LANDING_DATA = useStaticQuery(
    graphql`
      query {
        landingYaml {
          identityImageDark {
            image
          }
          backgroundImage {
            image
          }
          facebook
          instagram
          vimeo
        }
      }
    `
  );

  /** Desfragmenting props */
  const {backgroundImage, identityImageDark, facebook, instagram} = LANDING_DATA.landingYaml

  return (
    <DefaultPage pageTitle="Inicio">
      <LandingWrapper background={backgroundImage.image}>
        <SocialShare instagram={instagram} facebook={facebook}/>
        <LandingContainer>
          <HeadingNotVisible tabindex={1}>{D.landing.screenReaderMessage}</HeadingNotVisible>
          <StaticImgComponent maxWidth={380} alt={D.general.brandingAlt} src={identityImageDark.image}/>
          <ButtonL to="/home"><p>{D.landing.button}</p></ButtonL>
        </LandingContainer>
      </LandingWrapper>
    </DefaultPage>
  );
};

export default IndexPage;
